<template>
  <div class="pay-table-wrapper">
    <v-dialog v-model="event_obj.fill_button" persistent width="600">
      <v-card>
        <v-card-title class="pb-2">
          Заповнення абонентами
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch hide-details
                        v-model="fill_filter.all_flats"
                        color="success" class="mt-0"
                        label="Усі квартири"></v-switch>
            </v-col>
            <v-col cols="12">
              <AddressElementSelect v-model="fill_filter.buildings"
                                    label="Будинок(-ки)" multiple filled
                                    select_type="building" req
              />
            </v-col>
            <v-col cols="6">
              <v-switch hide-details color="success"
                        v-model="fill_filter.add_flats"
                        label="Додати помешкання (населення)"
              />
            </v-col>
            <v-col cols="6">
              <v-switch hide-details color="success"
                        v-model="fill_filter.add_legacy"
                        label="Додати помешкання (юр. особи)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="fill_filter.flat_number_from"
                            :disabled="fill_filter.all_flats"
                            filled color="grey darken-1"
                            hide-details
                            v-integer label="Номер квартири з"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="fill_filter.flat_number_to"
                            :disabled="fill_filter.all_flats"
                            filled color="grey darken-1"
                            hide-details
                            v-integer label="Номер квартири по"/>
            </v-col>
            <v-col cols="12" md="6">
              <date-component label="Дата приєднання"
                              v-model="fill_filter.date_start"
                              :req="true" :class_="fill_filter.date_start ? '' : 'req-star'"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="fill_users"
                 class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Виконати
          </v-btn>
          <v-btn depressed text tile color="grey" @click="close_fill_dialog">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="px-4">
      <v-tabs class="document-small-tabs" color="success">
        <v-tab>Населення</v-tab>
        <v-tab>Юр. особи</v-tab>

        <v-tab-item>
          <v-card-text class="px-0 pb-0">
            <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2">
              <v-icon left size="22">
                mdi-plus
              </v-icon>
              Додати рядок
            </v-btn>
            <v-btn small depressed color="error lighten-3" @click="deleteRows" :disabled="!selectedRows.length">
              <v-icon left size="22">
                mdi-delete
              </v-icon>
              Видалити рядок(-ки)
            </v-btn>
          </v-card-text>

          <div class="pay-table pt-4">
            <div class="pay-table-header">
              <div class="pay-table-row">
                <div class="pay-table-col"></div>
                <div class="pay-table-col">
                  №
                </div>
                <div class="pay-table-col">
                  Особовий
                </div>
                <div class="pay-table-col">
                  Адреса
                </div>
                <div class="pay-table-col">
                  Дата початку
                </div>
                <div class="pay-table-col">
                  Дата закінчення
                </div>
                <div class="pay-table-col">

                </div>
              </div>
            </div>
            <div class="pay-table-body" style="height: 70vh; overflow: auto; padding-bottom: 20px">
              <div
                  class="pay-table-row"
                  v-for="(item, idx) in items" :key="`${item.row_num}-flats`">
                <div
                    class="pay-table-col"
                    :class="{'col-error': item.error}"
                    style="padding-left: 10px !important;"
                >
                  <v-checkbox
                      class="select-checkbox"
                      color="success"
                      hide-details :ripple="false"
                      :value="selected_row === item.row_num"
                      @change="selectRow(item.row_num)"
                  />
                </div>
                <div class="pay-table-col">
                  {{ idx + 1 }}
                </div>
                <div class="pay-table-col editable">
                  <AUC_FlatSearch
                      :row_num="item.row_num"
                      :flat_id="item.flat_id"
                      :text_value="item.person_id"
                      :text_value_key="'person_id'"
                      :class="{'error-input': !item.person_id}"
                      @selectChange="flatChange"/>
                </div>
                <div class="pay-table-col">
                  {{ item.address }}
                </div>
                <div class="pay-table-col editable" style="position:relative;" :class="{'error-input': !item.date_start}">
                  <input type="text" :value="item.date_start | formatDate" readonly>
                  <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                    <DateComponentIcon v-model="item.date_start" :row_num="item.row_num" @changeDate="changeDateStart"/>
                  </div>
                </div>
                <div class="pay-table-col editable" style="position:relative;">
                  <input type="text" :value="item.date_end | formatDate" readonly>
                  <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                    <DateComponentIcon v-model="item.date_end" :row_num="item.row_num" @changeDate="changeDateEnd"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="px-0 pb-0">
            <v-btn small depressed color="grey lighten-3" @click="addNewRowLegacy" class="mr-2">
              <v-icon left size="22">
                mdi-plus
              </v-icon>
              Додати рядок
            </v-btn>
            <v-btn small depressed color="error lighten-3" @click="deleteRowsLegacy"
                   :disabled="!selectedRowsLegacy.length">
              <v-icon left size="22">
                mdi-delete
              </v-icon>
              Видалити рядок(-ки)
            </v-btn>
          </v-card-text>

          <div class="pay-table legacy pt-4">
            <div class="pay-table-header">
              <div class="pay-table-row">
                <div class="pay-table-col"></div>
                <div class="pay-table-col">
                  №
                </div>
                <div class="pay-table-col">
                  Особовий
                </div>
                <div class="pay-table-col">
                  Договір
                </div>
                <div class="pay-table-col">
                  Платник
                </div>
                <div class="pay-table-col">
                  Дата початку
                </div>
                <div class="pay-table-col">
                  Дата закінчення
                </div>
                <div class="pay-table-col">

                </div>
              </div>
            </div>
            <div class="pay-table-body" style="height: 70vh; overflow: auto; padding-bottom: 20px">
              <div
                  class="pay-table-row"
                  v-for="(item, idx) in items_legacy" :key="`${item.row_num}-legacy`">
                <div
                    class="pay-table-col"
                    :class="{'col-error': item.error}"
                    style="padding-left: 10px !important;"
                >
                  <v-checkbox
                      class="select-checkbox"
                      color="success"
                      hide-details :ripple="false"
                      :value="selected_row_legacy === item.row_num"
                      @change="selectRowLegacy(item.row_num)"
                  />
                </div>
                <div class="pay-table-col">
                  {{ idx + 1 }}
                </div>
                <div class="pay-table-col editable">
                  <AUC_FlatSearchLegacy
                      :row_num="item.row_num"
                      :flat_id="item.flat_id"
                      :text_value="item.person_id"
                      :text_value_key="'person_id'"
                      :class="{'error-input': !item.person_id}"
                      @selectChange="flatChangeLegacy"
                  />
                </div>
                <div class="pay-table-col editable">
                  <ACC_FlatSearchContract
                      :value="item.contract_name"
                      :row_num="item.row_num"
                      :flat_id="item.flat_id"
                      @selectChange="flatContractChange"
                      item-value="text"
                      :class="{'error-input': !item.person_id}"
                  />
                </div>
                <div class="pay-table-col">
                  {{ item.address }}
                </div>
                <div class="pay-table-col editable" style="position:relative;" :class="{'error-input': !item.date_start}">
                  <input type="text" :value="item.date_start | formatDate" readonly>
                  <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                    <DateComponentIcon v-model="item.date_start" :row_num="item.row_num" @changeDate="changeDateStartLegacy"/>
                  </div>
                </div>
                <div class="pay-table-col editable" style="position:relative;">
                  <input type="text" :value="item.date_end | formatDate" readonly>
                  <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                    <DateComponentIcon v-model="item.date_end" :row_num="item.row_num" @changeDate="changeDateEndLegacy"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>

  </div>
</template>

<script>
import {formatDate} from "@/filters";
import buildingCounterAPI from "@/utils/axios/building_counter"
import {mapGetters} from "vuex";
import {maxBy} from "lodash";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

export default {
  name: "BuildingCounterUser",
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    update_data: {
      type: Boolean,
      default: false
    },
    building_counter_id: {
      type: Number,
      default: 0
    },
    building_id: {
      type: Number,
      default: 0
    },
    event_obj: {
      type: Object
    }
  },
  components: {
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    AUC_FlatSearchLegacy: () => import("@/components/tableComponent/AUC_FlatSearchLegacy.vue"),
    ACC_FlatSearchContract: () => import("@/components/tableComponent/ACC_FlatSearchContract.vue"),
    DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth',
      modalAnswer: 'question_answer',
    }),
  },
  data() {
    return {
      selected_row: 0,
      selected_row_legacy: 0,
      expand_row_num: 0,
      expand_row_num_legacy: 0,
      modal_watch: null,
      update_watch: null,
      delete_watcher: null,
      items: [],
      items_legacy: [],
      selectedRows: [],
      selectedRowsLegacy: [],
      fill_filter: {
        flat_number_from: 0,
        flat_number_to: 0,
        date_start: null,
        all_flats: false,
        add_legacy: false,
        add_flats: true,
        buildings: []
      },
    }
  },
  methods: {
    formatDate,
    buildingChange(payload) {
      if (payload) {
        this.fill_filter.buildings = payload.map(item => item.value)
      } else {
        this.fill_filter.buildings = []
      }
    },
    fetchArchive() {
      buildingCounterAPI.get_counter_user(this.building_counter_id)
          .then(response => response.data)
          .then(data => {
            if (this.building_id) {
              this.fill_filter.buildings = [this.building_id]
            }

            this.items = data.filter(i => !i.is_legacy).map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
            this.items_legacy = data.filter(i => i.is_legacy).map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    modal_watcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.fetchArchive()
              } else {
                this.items = []
                this.items_legacy = []
                this.selectedRows = []
                this.selectedRowsLegacy = []
                this.$emit('selectedRowsEmit', [])
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === 'clear_user_flats') {
                if (payload.answer) {
                  this.items = []
                  this.items_legacy = []
                  this.$store.commit(ALERT_SHOW, {text: 'Для фіксації змін виконайте команду "Зберегти"', color: 'success'})
                }
              }
            }
          }
      )
    },
    clear_all_user_flats() {
      const payload = {
        text: `Підтвердіть очищення усіх абонентів із таблиці?`,
        accept_button: true,
        id: 'clear_user_flats'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    flatContractChange(payload) {
      const current_row = this.items_legacy.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.contract_name = payload.text || ''
        current_row.contract_id = payload.value
      }
    },
    flatChange(payload) {
      const current_row = this.items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = payload.address
        current_row.person_id = payload.person_id
        current_row.date_start = this.current_month
        current_row.flat_id = payload.value
        current_row.error = false
      }
    },
    flatChangeLegacy(payload) {
      const current_row = this.items_legacy.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = `${payload.person_id}, ${payload.contractor_name}`
        current_row.person_id = payload.person_id
        current_row.flat_id = payload.value
        current_row.contract_id = payload.contract_id
        current_row.contract_name = payload.contract_name
        current_row.date_start = this.current_month
        current_row.error = false
      }
    },
    selectRow(row_num) {
      if (row_num) {
        if (this.selectedRows.indexOf(row_num) > -1) {
          this.selectedRows = this.selectedRows.filter(item => item !== row_num)
        } else {
          this.selectedRows.push(row_num)
        }
      }
    },
    selectRowLegacy(row_num) {
      if (row_num) {
        if (this.selectedRowsLegacy.indexOf(row_num) > -1) {
          this.selectedRowsLegacy = this.selectedRowsLegacy.filter(item => item !== row_num)
        } else {
          this.selectedRowsLegacy.push(row_num)
        }
      }
    },
    fill_users() {
      if (!this.fill_filter.date_start) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть дату приєднання', color: 'error'})
        return
      }
      const payload = {
        building_counter_id: this.building_counter_id,
        filters: this.fill_filter
      }

      buildingCounterAPI.fill_counter_user(payload)
          .then(response => response.data)
          .then(data => {
            const max_row_item = maxBy(this.items, 'row_num')
            const max_row_item_legacy = maxBy(this.items_legacy, 'row_num')

            let max_row = 0
            let max_row_legacy = 0
            if (max_row_item) {
              max_row = max_row_item.row_num
            }
            if (max_row_item_legacy) {
              max_row_legacy = max_row_item_legacy.row_num
            }

            let counter = 0
            // eslint-disable-next-line no-unused-vars
            let counter_legacy = 0

            data.filter(i => !i.is_legacy).forEach(item => {
              const present_row = this.items.find(i => i.flat_id === item.flat_id)
              if (!present_row) {
                counter += 1

                this.items.push(
                    {
                      id: 0,
                      flat_id: item.flat_id,
                      date_start: item.date_start,
                      date_end: null,
                      address: item.address,
                      person_id: item.person_id,
                      row_num: max_row + counter,
                      error: false,
                      is_legacy: false
                    }
                )
              }
            })
            this.items.sort((a, b) => {
              const nameA = a.person_id.toUpperCase(); // ignore upper and lowercase
              const nameB = b.person_id.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0})

            data.filter(i => i.is_legacy).forEach(item => {
              const present_row = this.items_legacy.find(i => (i.flat_id === item.flat_id && i.contract_id === item.contract_id))
              if (!present_row) {
                counter_legacy += 1

                this.items_legacy.push(
                    {
                      id: 0,
                      flat_id: item.flat_id,
                      date_start: item.date_start,
                      date_end: null,
                      address: item.address,
                      person_id: item.person_id,
                      row_num: max_row_legacy + counter,
                      error: false,
                      is_legacy: true,
                      contract_id: item.contract_id,
                      contract_name: item.contract_name
                    }
                )
              }
            })
            this.items_legacy.sort((a, b) => {
              const nameA = a.person_id.toUpperCase(); // ignore upper and lowercase
              const nameB = b.person_id.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0})


            this.$store.commit(ALERT_SHOW, {text: 'Для фіксації змін виконайте команду "Зберегти"', color: 'success'})
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.event_obj.fill_button = false
          })
    },
    close_fill_dialog() {
      this.event_obj.fill_button = false
      this.fill_filter.all_flats = false
      this.fill_filter.flat_number_from = 0
      this.fill_filter.flat_number_to = 0
      this.fill_filter.date_start = null
    },
    save_or_update() {
      const payload = {
        building_counter_id: this.building_counter_id,
        rows: []
      }

      this.items.forEach(item => {
        payload.rows.push(
            {
              flat_id: item.flat_id,
              building_counter_id: this.building_counter_id,
              date_start: item.date_start,
              date_end: item.date_end,
              is_legacy: item.is_legacy,
              contract_id: item.contract_id,
              error: item.error
            }
        )
      })

      this.items_legacy.forEach(item => {
        payload.rows.push(
            {
              flat_id: item.flat_id,
              building_counter_id: this.building_counter_id,
              date_start: item.date_start,
              date_end: item.date_end,
              is_legacy: item.is_legacy,
              contract_id: item.contract_id,
              error: item.error
            }
        )
      })

      const errors = payload.rows.filter(i => i.error).length

      if (errors) {
        this.$store.commit(ALERT_SHOW,
            {
              text: 'Виправте помилки підкреслені червоним у табличних частинах (Населення та Юр. особи)',
              color: 'error lighten-1'
            })
        return
      }

      buildingCounterAPI.create_or_update_counter_user(payload)
          .then(response => response.data)
          .then(data => {
            this.items = data.filter(i => !i.is_legacy).map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
            this.items_legacy = data.filter(i => i.is_legacy).map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    addNewRow() {
      const max_row_item = maxBy(this.items, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        id: 0,
        flat_id: 0,
        date_start: null,
        date_end: null,
        address: "",
        person_id: "",
        row_num: max_row + 1,
        error: true,
        is_legacy: false,
        contract_id: null
      }
      this.items.push(new_row)
    },
    addNewRowLegacy() {
      const max_row_item = maxBy(this.items_legacy, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        id: 0,
        flat_id: 0,
        date_start: null,
        date_end: null,
        address: "",
        person_id: "",
        row_num: max_row + 1,
        error: true,
        is_legacy: true,
        contract_id: null
      }
      this.items_legacy.push(new_row)
    },
    deleteRows() {
      const max_row_item = maxBy(this.items, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }
      this.selectedRows.forEach(item => {
        const tab_item = this.items.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.items.indexOf(tab_item)
          this.items.splice(idx, 1)
        }
      })
      this.items.forEach((item, idx) => {
        item['row_num'] = max_row + idx + 1
      })
      this.selectedRows = []
      this.$store.commit(ALERT_SHOW, {text: 'Для фіксації змін виконайте команду "Зберегти"', color: 'success'})
    },
    deleteRowsLegacy() {
      const max_row_item = maxBy(this.items_legacy, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }
      this.selectedRowsLegacy.forEach(item => {
        const tab_item = this.items_legacy.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.items_legacy.indexOf(tab_item)
          this.items_legacy.splice(idx, 1)
        }
      })
      this.items_legacy.forEach((item, idx) => {
        item['row_num'] = max_row + idx + 1
      })
      this.selectedRowsLegacy = []
      this.$store.commit(ALERT_SHOW, {text: 'Для фіксації змін виконайте команду "Зберегти"', color: 'success'})
    },
    changeDateStart(payload) {
      const current_row = this.items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.date_start = payload.value
      }
    },
    changeDateStartLegacy(payload) {
      const current_row = this.items_legacy.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.date_start = payload.value
      }
    },
    changeDateEnd(payload) {
      const current_row = this.items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.date_end = payload.value
      }
    },
    changeDateEndLegacy(payload) {
      const current_row = this.items_legacy.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.date_end = payload.value
      }
    }
  },
  created() {
    this.modal_watcher()
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.modal_watch) {
      this.modal_watch()
    }
    if (this.update_watch) {
      this.update_watch()
    }
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  },
  watch: {
    event_obj: {
      immediate: false,
      deep: true,
      handler(payload) {
        if (payload.save_button) {
          this.save_or_update()
          payload.save_button = false
        }
        if (payload.clear_all_button) {
          this.clear_all_user_flats()
          payload.clear_all_button = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: rgba(0, 0, 0, .12);
$expand-border-color: rgba(0, 0, 0, .05);
$expand-background-col: #f0f0f0;

.pay-table {
  font-size: .84rem;
}

.expand-icon:focus:after {
  opacity: 0 !important;
}

.pay-table-col {
  padding: 6px 2px !important;
  //border-right: thin solid $border-color;

  &:nth-child(1) {
    flex: 0 1 60px;
    padding-left: 16px !important;
    min-width: 60px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }

  &:nth-child(3) {
    flex: 0 1 140px;
    min-width: 120px;
  }

  &:nth-child(4) {
    flex: 0 1 340px;
    min-width: 240px;
  }

  &:nth-child(5) {
    flex: 0 1 120px;
    min-width: 120px;
  }

  &:nth-child(6) {
    flex: 0 1 120px;
    min-width: 120px;
  }

  &:nth-child(7) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 140px;
  }
}

.pay-table.legacy {
  .pay-table-col {
    padding: 6px 2px !important;
    //border-right: thin solid $border-color;

    &:nth-child(1) {
      flex: 0 1 60px;
      padding-left: 16px !important;
      min-width: 60px;
    }

    &:nth-child(2) {
      flex: 0 1 50px;
      min-width: 50px;
    }

    &:nth-child(3) {
      flex: 0 1 140px;
      min-width: 120px;
    }

    &:nth-child(4) {
      flex: 0 1 140px;
      min-width: 120px;
    }

    &:nth-child(5) {
      flex: 0 1 340px;
      min-width: 240px;
    }

    &:nth-child(6) {
      flex: 0 1 120px;
      min-width: 120px;
    }

    &:nth-child(7) {
      flex: 0 1 120px;
      min-width: 120px;
    }

    &:nth-child(8) {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      min-width: 140px;
    }
  }
}

.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 935px;
        min-width: 785px;
      }

      &:nth-child(2) {
        flex: 0 1 100px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 100px;
      }

      &:nth-child(3) {
        flex: 0 1 110px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 110px;
      }

      &:nth-child(4) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 180px;
      }
    }
  }
}

</style>